import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import colors from "vuetify/lib/util/colors";

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        light: true,
        themes: {
            light: {
                primary: '#00675b',
                secondary: '#007164',
                accent: colors.teal.accent1,
                error: colors.red.accent3,
            },
            dark: {
                primary: colors.blueGrey.darken2,
                secondary: colors.blueGrey.lighten2,
                accent: colors.blueGrey.darken3,
            },
        },
    },
});