<template>
    <v-container fluid>
        <v-row justify="start">
            <v-spacer/>
            <v-col cols="12" md="8" sm="10" xs="10">
                <span class="text-h4 title">Terms of Service</span>
                <p>Last Updated: November 30, 2020. </p>
            </v-col>
            <v-spacer/>
        </v-row>
        <v-row justify="start">
            <v-spacer/>
            <v-col cols="12" md="8" sm="10" xs="10">
                <p>
                    The following terms and conditions (“Terms”) govern all use of our website (including
                    mealsbytheday.com) and our mobile applications and related services (collectively referred to as
                    “Services”).
                </p>
                <p>
                    Our Services are offered subject to your acceptance without modification of all of the terms and
                    conditions contained herein and all other operating rules, policies (including, without limitation,
                    <a href="privacy">Kombubrew's Privacy Policy</a>) and procedures that may be published from time to
                    time by Kombubrew
                    (collectively, the “Agreement”). You agree that we may automatically upgrade our Services, and these
                    Terms will apply to any upgrades. Your agreement is with Kombubrew LLC (“Kombubrew”, “we”, “our”, or
                    “us”).
                </p>
                <p>
                    Please read this Agreement carefully before accessing or using our Services. By accessing or using
                    any part of our Services, you agree to become bound by the Terms of this Agreement. If you do not
                    agree to all the Terms of this Agreement, then you may not access or use any of our Services.
                </p>
                <p>
                    Our Services are not directed to persons younger than 18, and access and use of our Services is only
                    offered to users 18 years of age or older located in the United States. If you are under 18 years
                    old, please do not register to use our Services. Any person who registers as a user or provides
                    their personal information to our Services represents that they are 18 years of age or older.
                </p>
                <p>
                    Use of our Services requires an account. You agree to provide us with complete and accurate
                    information when you register for an account. You will be solely responsible and liable for any
                    activity that occurs under your account. You are responsible for keeping your password secure.
                </p>


                <h3>Changes.</h3>
                <p>We are constantly updating our Services, and that means sometimes we have to change the legal terms
                    under which our Services are offered when and as we feel they need to be updated. All changes are
                    effective immediately when we post them, and apply to your use of the Services when we change them.
                    We encourage you to check these Terms often for updates. Your continued use of our Services after we
                    change the Terms means that you accept and agree to the changes. If you disagree with our changes,
                    then you should stop using our Services.</p>

                <h3>Accessing the Services.</h3>

                <p>We reserve the right to remove or change the Services, and any material we provide in connection with
                    the Services as we want without notifying you. We may restrict access to some parts of the Services,
                    or all of the Services to our users. We will not be liable for any reason if all or any part of the
                    Services are unavailable at any time or for any period.</p>

                <h3>Geographic Restrictions.</h3>

                <p>We make no claims that our Services is accessible or appropriate outside of the United States. Access
                    to our Services may not be legal by certain persons or in certain countries. If you access our
                    Services from outside the United States, you do so on your own initiative and are responsible for
                    compliance with local laws.</p>


                <h3>Your Account</h3>
                <p>
                    You are responsible for maintaining the security of your account, and you are fully responsible for
                    all activities that occur under the account and any other actions taken in connection with your
                    account. You must immediately notify Kombubrew of any unauthorized uses of your account, or any
                    other breaches of security. Kombubrew will not be liable for any acts or omissions by you, including
                    any damages of any kind incurred as a result of such acts or omissions.
                </p>
                <h3>Responsibility of Contributors.</h3>
                <p>
                    If you post material to our Services, or otherwise make (or allow any third party to make) material
                    available (any such material, “Content”), you are entirely responsible for the content of, and any
                    harm resulting from, that Content or your conduct. That is the case regardless of what form the
                    Content takes, which includes, but is not limited to text, photo, video, audio, or code. By using
                    our Services, you represent and warrant that your Content and conduct do not violate these Terms. By
                    submitting Content to our Services, you grant Kombubrew a world-wide, royalty-free, and
                    non-exclusive license to reproduce, modify, adapt and publish the Content solely for the purpose of
                    displaying, distributing, and promoting your Content. This license allows Kombubrew to make Content
                    available to third parties selected by Kombubrew so that these third parties can analyze and
                    distribute your content through their services. If you delete Content, Kombubrew will use reasonable
                    efforts to remove it from our Services, but you acknowledge that caching or references to the
                    Content may not be made immediately unavailable. Without limiting any of those representations or
                    warranties, Kombubrew has the right (though not the obligation) to, in Kombubrew’s sole discretion,
                    (i) reclaim your account due to prolonged inactivity, (ii) refuse or remove any content that, in
                    Kombubrew’s reasonable opinion, violates any Kombubrew’s policy or is in any way harmful or
                    objectionable, or (iii) terminate or deny access to and use of our Services to any individual or
                    entity for any reason.
                </p>

                <h3>Feedback.</h3>
                <p>If you provide any feedback about our Services, then you agree Kombubrew can use such feedback in its
                    discretion without any obligation, attribution, or compensation to you, and you waive all rights in
                    the feedback you have provided.</p>

                <h3>Advertisements.</h3>
                <p>
                    Kombubrew does not display advertisements. However, we reserves the right to display
                    advertisements at any time.
                </p>
                <h3>Responsibility of Visitors.</h3>
                <p>
                    Kombubrew has not reviewed, and cannot review, all of the material, including computer software,
                    posted to our Services, and cannot therefore be responsible for that material’s content, use or
                    effects. By operating our Services, Kombubrew does not represent or imply that it endorses the
                    material there posted, or that it believes such material to be accurate, useful, or non-harmful. You
                    are responsible for taking precautions as necessary to protect yourself and your computer systems
                    from viruses, worms, Trojan horses, and other harmful or destructive content. Our Services may
                    contain content that is offensive, indecent, or otherwise objectionable, as well as content
                    containing technical inaccuracies, typographical mistakes, and other errors. Our Services may also
                    contain material that violates the privacy or publicity rights, or infringes the intellectual
                    property and other proprietary rights, of third parties, or the downloading, copying or use of which
                    is subject to additional terms and conditions, stated or unstated. Kombubrew disclaims any
                    responsibility for any harm resulting from the use by visitors of our Services, or from any
                    downloading by those visitors of content there posted.
                </p>
                <h3>Content Posted on Other Websites.</h3>
                <p>
                    We have not reviewed, and cannot review, all of the material, including computer software, made
                    available through the websites and webpages to which our Services links, and that link to our
                    Services. Kombubrew does not have any control over those third-party websites, and is not
                    responsible for their contents or their use. By linking to a third-party website, Kombubrew does not
                    represent or imply that it endorses such website. You are responsible for taking precautions as
                    necessary to protect yourself and your computer systems from viruses, worms, Trojan horses, and
                    other harmful or destructive content. Kombubrew disclaims any responsibility for any harm resulting
                    from your use of third-party websites and webpages.
                </p>
                <h3>Copyright Infringement and DMCA Policy.</h3>
                <p>
                    If you believe that material located on or linked to by our Services violates your copyright, you
                    are encouraged to notify Kombubrew at copyright@mealsbytheday.com. Kombubrew will respond to all
                    such notices, including as required or appropriate by removing the infringing material or disabling
                    all links to the infringing material. Kombubrew will terminate a user’s access to and use of our
                    Services if, under appropriate circumstances, the user is determined to be a repeat infringer of the
                    copyrights or other intellectual property rights of Kombubrew or others. In the case of such
                    termination, Kombubrew will have no obligation to provide a refund of any amounts previously paid to
                    Kombubrew.
                </p>
                <h3>Intellectual Property.</h3>
                <p>
                    This Agreement does not transfer from Kombubrew to you any Kombubrew or third party intellectual
                    property, and all right, title, and interest in and to such property will remain (as between the
                    parties) solely with Kombubrew. Kombubrew, and all other trademarks, service marks, graphics and
                    logos used in connection with our Services, are trademarks or registered trademarks of Kombubrew or
                    Kombubrew’s licensors. Other trademarks, service marks, graphics and logos used in connection with
                    our Services may be the trademarks of other third parties. Your use of our Services grants you no
                    right or license to reproduce or otherwise use any Kombubrew or third-party trademarks.
                </p>
                <h3>Premium Account Upgrade.</h3>
                <p>Your Premium Account is intended for the use of a single person and should not be shared with anyone.
                    Sharing your Premium Account with others may result in termination of your account. You must have an
                    account in good standing with an active Premium Account upgrade to access premium features.
                    Kombubrew reserves the right to terminate any account at any time for any reason, in which case we
                    may choose to refund the cost of your Premium Account upgrade at our discretion.
                    Premium Account upgrades are non-refundable and non-transferable.</p>

                <h3>Termination.</h3>
                <p>
                    Kombubrew may terminate your access to all or any part of our Services at any time, with or without
                    cause, with or without notice, effective immediately. If you wish to terminate this Agreement or
                    your account (if you have one), you may simply discontinue using our Services. All provisions of
                    this Agreement which by their nature should survive termination shall survive termination,
                    including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations
                    of liability.
                </p>
                <h3>Disclaimer of Warranties.</h3>
                <p>
                    Our Services are provided “as is.” Kombubrew and its suppliers and licensors hereby disclaim all
                    warranties of any kind, express or implied, including, without limitation, the warranties of
                    merchantability, fitness for a particular purpose and non-infringement. Neither Kombubrew nor its
                    suppliers and licensors, makes any warranty that our Services will be error free or that access
                    thereto will be continuous or uninterrupted. You understand that you download from, or otherwise
                    obtain content or services through, our Services at your own discretion and risk.
                </p>
                <h3>Jurisdiction and Applicable Law.</h3>
                <p>
                    Except to the extent any applicable law provides otherwise, this Agreement, any access to or use of
                    our Services will be governed by the laws of the state of Oregon, U.S.A., excluding its conflict of
                    law provisions, and the proper venue for any disputes arising out of or relating to any of the same
                    will be the state and federal courts located in the City of Portland and County of Multnomah.
                </p>
                <h3>No Class Actions.</h3>
                <p>You may not bring a claim as a plaintiff or a class member in a class, consolidated, or
                    representative action, including class arbitrations, class actions, and private attorney general
                    actions.</p>

                <h3>Waiver of Jury Trial.</h3>
                <p>
                    Each party irrevocably and unconditionally waives, to the fullest extent permitted by applicable
                    law, any right it may have to a trial by jury in any legal action, proceeding, cause of action or
                    counterclaim arising out of or relating to these terms, or the subject matter of these terms.
                </p>
                <h3>Limitation of Liability.</h3>
                <p>
                    In no event will Kombubrew, or its suppliers or licensors, be liable with respect to any subject
                    matter of this Agreement under any contract, negligence, strict liability or other legal or
                    equitable theory for: (i) any special, incidental or consequential damages; (ii) the cost of
                    procurement for substitute products or services; (iii) for interruption of use or loss or corruption
                    of data; or (iv) for any amounts that exceed the fees paid by you to Kombubrew under this Agreement
                    during the twelve (12) month period prior to the cause of action. If the service is provided at no
                    charge to you, Kombubrew shall have no liability whatsoever to you. The foregoing shall not apply to
                    the extent prohibited by applicable law.
                </p>
                <h3>General Representation and Warranty.</h3>
                <p>
                    You represent and warrant that (i) your use of our Services will be in strict accordance with the
                    Kombubrew’s Privacy Policy, with this Agreement, and with all applicable laws and regulations
                    (including without limitation any local laws or regulations in your country, state, city, or other
                    governmental area, regarding online conduct and acceptable content, and including all applicable
                    laws regarding the transmission of technical data exported from the United States or the country in
                    which you reside) and (ii) your use of our Services will not infringe or misappropriate the
                    intellectual property rights of any third party.
                </p>
                <h3>US Economic Sanctions.</h3>
                <p>
                    You expressly represent and warrant that your use of our Services and or associated services and
                    products is not contrary to applicable U.S. Sanctions. Such use is prohibited, and Kombubrew
                    reserves the right to terminate accounts or access of those in the event of a breach of this
                    condition.
                </p>
                <h3>Indemnification.</h3>
                <p>
                    You agree to indemnify and hold harmless Kombubrew, its contractors, and its licensors, and their
                    respective directors, officers, employees, and agents from and against any and all claims and
                    expenses, including attorneys’ fees, arising out of your use of our Services, including but not
                    limited to your violation of this Agreement.
                </p>
                <h3>Miscellaneous.</h3>
                <p>This Agreement constitutes the entire agreement between Kombubrew and you concerning the subject
                    matter hereof, and they may only be modified by the posting by Kombubrew of a revised version.</p>
                <p>If any part of this Agreement is held invalid or unenforceable, that part will be construed to
                    reflect the parties’ original intent, and the remaining portions will remain in full force and
                    effect. A waiver by either party of any term or condition of this Agreement or any breach thereof,
                    in any one instance, will not waive such term or condition or any subsequent breach thereof.</p>
                <p>Use of the terms “including” and “include” indicate non-exclusive lists of items.</p>

            </v-col>
            <v-spacer/>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        name: "TermsOfService"
    }
</script>

<style scoped>
    .title {
        font-weight: 500;
    }
</style>