<template>
    <v-container fluid class="banner-background">
        <v-row class="banner-padding" justify="center">
            <v-col cols="12" md="4" sm="6" xs="12" class="figure hidden-sm-and-up">
                <v-img contain height="650" :src="require('@/assets/images/' + src)"></v-img>
            </v-col>
            <v-col cols="12" md="8" sm="6" xs="12" align-self="center" class="figure-body">
                <v-row justify="end" class="text-left text">
                    <v-col cols="12" md="8" sm="11" xs="12">
                        <span class="title">
                            <slot name="title"></slot>
                        </span>
                    </v-col>
                </v-row>
                <v-row justify="end" class="text-left text-lg-body-1">
                    <v-col cols="12" md="8" sm="11" xs="12" align-self="center">
                        <span>
                            <slot name="body1"></slot>
                        </span>
                    </v-col>
                </v-row>
                <v-row justify="start" class="text-left text-lg-body-1">
                    <v-col cols="12" md="8" sm="11" xs="12" align-self="center">
                        <span>
                           <slot name="body2"></slot>
                        </span>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" md="4" sm="6" xs="12" class="figure hidden-xs-only">
                <v-img contain height="650" :src="require('@/assets/images/' + src)"></v-img>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        name: "RightBanner",
        props: {
            src: {
                type: String
            }
        },
        computed: {
            TitleSize(){
                return this.$vuetify.breakpoint.name == 'xs' ? 'text-h6' : 'text-h4';
            }
        },
    }
</script>

<style scoped>
    .banner-background {
        background-color: #f3f3f2;
        width: 100%;
        height: 100%;
        background-size: cover;
        overflow: hidden;
        /*position: absolute;*/
        top: 0;
        left: 0;
        z-index: 1;
        /*z-index: 2;*/
        /*height: 100%;*/
    }
    .banner-padding {
        padding-right: 4em;
    }
    .figure {
        padding-top: 3em;
    }
    .figure-body {
        padding-right: 4em;
    }
    .title {
        font-size: 2em !important;
        font-weight: 500;
    }

    @media screen and (max-width: 768px) {
        .banner-padding {
            padding-left: 0em;
            padding-bottom: 0em;
            padding-right: 0em;
        }
        .figure {
            padding-top: 1em;

        }
        .figure-body {
            text-align: center;
            padding-left: 4em;
            padding-right: 2em;
        }
        .title {
            font-size: 1.5em !important;
            font-weight: 500;
        }
    }
</style>
