<template>
    <v-btn color="secondary" block v-on="$listeners" :disabled="!valid">
    <slot></slot>
    </v-btn>
</template>

<script>
    export default {
        name: "AppButton",
        props: {
            valid: {
                type: Boolean,
            }
        }
    }
</script>

<style scoped>
</style>