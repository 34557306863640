<template>
  <v-container fluid class="pt-5">
    <v-row align-content="center">
      <v-spacer/>
      <AccountHome/>
      <v-spacer/>
    </v-row>
  </v-container>
</template>

<script>

import AccountHome from "./AccountHome";

export default {
  name: 'Home',
  components: {
    AccountHome
  }
}
</script>
<style scoped>

</style>
