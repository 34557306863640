<template>
  <v-app light>
    <TheAppbar/>
    <v-main>
      <router-view/>
    </v-main>
    <TheFooter/>
  </v-app>
</template>

<script>
import TheAppbar from "./components/TheAppbar";
import TheFooter from "./components/TheFooter";

export default {
  name: 'App',

  components: {
    TheAppbar,
    TheFooter
  },

  data(){
    return {

    }
  },
  computed:{

  }
};
</script>
<style>
  .txt-field {
    width: 100%;
  }
  .custom-w-h{
    height: 100%;
    width: 80%;
  }
</style>