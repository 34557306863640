<template>
    <v-container fluid>
        <v-row justify="center">
            <v-col cols="12" md="5" sm="8" xs="12">
                <v-card flat tile :outlined="getBreakpoint">
                    <v-row justify="center" class="pt-3">
                        <v-avatar>
                            <v-img src="@/assets/logo.svg"></v-img>
                        </v-avatar>
                    </v-row>
                    <v-card-title class="justify-center text-h6">Confirm Account</v-card-title>
                    <v-row no-gutters wrap justify="center" v-if="errorMsg">
                        <v-col cols="12" md="9" sm="12" xs="12">
                            <v-alert text dense type="error">
                                {{errorMsg}}
                            </v-alert>
                        </v-col>
                    </v-row>
                    <v-row justify="center" v-if="loading">
                        <v-col cols="12" md="9" sm="12" xs="12" class="pl-2 pr-2 text-center">
                            <v-progress-circular :size="30" color="secondary" indeterminate></v-progress-circular>
                        </v-col>
                    </v-row>
                    <v-card-text v-if="!loading && !errorMsg">
                        <v-row justify="center">
                            <v-spacer></v-spacer>
                            <v-col cols="12" md="10" sm="12" xs="12" class="pt-5">
                                <v-label>You have successfully confirmed your account. You can now log in with the email {{email}}.</v-label>
                            </v-col>
                            <v-spacer></v-spacer>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import account from "../../api/account";

    export default {
        name: "AccountVerify",
        props: {
            sub: {
                type: String,
                required: true,
            },
            code: {
                type: String,
                required: true,
            }
        },
        computed: {
            getBreakpoint(){
                return this.$vuetify.breakpoint.name == 'xs' ? false : true;
            }
        },
        mounted: function () {
            this.loading = true;
            account.verify(this.sub, this.code).then(res => {
                console.log(res.data);
                this.email = res.data.email;
                this.loading = false;
            }).catch(err => {
                this.errorMsg = err.response.data.error;
                this.loading = false;
            });
        },
        data(){
          return {
              errorMsg: null,
              email: "",
              loading: false
          }
        },
        methods: {

        }
    }
</script>

<style scoped>

</style>