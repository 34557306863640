<template>
    <v-app-bar dense app hide-on-scroll flat color="primary">
        <v-toolbar-title class="white--text">
            <v-btn icon @click="goHome">
                <img contain src="@/assets/images/logo.svg" height="44" alt="Meals by the day logo" />
            </v-btn>
            <v-btn text color="white" @click="goHome">Meals By the Day Pro</v-btn>
        </v-toolbar-title>
        <v-spacer/>
<!--        <v-btn text class="hidden-xs-only pr-4 btn font-weight-regular" color="white" @click="newAccount" v-if="!isAuthenticated">New account</v-btn>-->
<!--        <v-btn text class="hidden-xs-only pr-4 btn font-weight-regular" color="white" @click="signIn" v-if="!isAuthenticated">Sign in</v-btn>-->
<!--        <v-btn text class="hidden-xs-only pr-4 btn font-weight-regular" color="white" @click="signOut()" v-if="isAuthenticated">Sign out</v-btn>-->
        <v-btn text class="hidden-xs-only pr-4 btn font-weight-regular" color="white" :href="faqLink">{{faq}}</v-btn>
        <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
                <v-app-bar-nav-icon  color="white" dark v-bind="attrs" v-on="on" class="hidden-sm-and-up"></v-app-bar-nav-icon>
            </template>
            <v-list>
<!--                <v-list-item v-if="isAuthenticated">-->
<!--                    <v-list-item-title @click="signOut()">-->
<!--                        Sign out-->
<!--                    </v-list-item-title>-->
<!--                </v-list-item>-->
<!--                <v-list-item v-if="!isAuthenticated">-->
<!--                    <v-list-item-title>-->
<!--                        <v-btn class="btn font-weight-regular" text @click="signIn">Sign in</v-btn>-->
<!--                    </v-list-item-title>-->
<!--                </v-list-item>-->
<!--                <v-list-item v-if="!isAuthenticated">-->
<!--                    <v-list-item-title>-->
<!--                        <v-list-item-title>-->
<!--                            <v-btn class="btn font-weight-regular" text @click="newAccount">New account</v-btn>-->
<!--                        </v-list-item-title>-->
<!--                    </v-list-item-title>-->
<!--                </v-list-item>                -->
                <v-list-item>
                    <v-list-item-title>
                        <v-list-item-title>
                            <v-btn class="btn font-weight-regular" text :href="faqLink">{{faq}}</v-btn>
                        </v-list-item-title>
                    </v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
    </v-app-bar>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    export default {
        name: "TheAppBar",
        computed: {
            ...mapGetters({
                isAuthenticated: 'isAuthenticated',
            })
        },
        data(){
            return {
                faq: 'Help & Support',
                faqLink: 'https://www.notion.so/Meals-By-the-Day-PRO-Help-Support-60a46c4838784676bf6276c98a816c90',
            }
        },
        methods: {
            ...mapActions({
                logout: 'logout'
            }),
            signOut(){
                this.logout().catch(err => {
                    console.log(err);
                })
            },
            signIn(){
                this.$router.push({ name: 'SignIn'});
            }          ,
            newAccount(){
                this.$router.push({ name: 'NewAccount'});
            },
            goHome(){
                this.$router.push({ path: '/'});
            },
        }
    }
</script>

<style scoped>
    .btn {
        text-transform: none;
    }
</style>