<template>
    <v-container fluid>
        <v-row justify="center">
            <v-col cols="12" md="5" sm="8" xs="12">
                <v-card class="justify-center" tile flat :outlined="getBreakpoint">
                    <v-row justify="center" class="pt-3">
                        <v-avatar>
                            <v-img src="@/assets/logo.svg"></v-img>
                        </v-avatar>
                    </v-row>
                    <v-card-title class="justify-center text-h6">Sign in</v-card-title>
                    <v-card-text>
                        <v-form v-model="isFormValid">
                            <v-row no-gutters>
                                <v-spacer></v-spacer>
                                <v-col cols="12" md="10" sm="12" xs="12">
                                    <v-text-field dense outlined :prepend-inner-icon="'mdi-email'" v-model="email" :rules="rules.email" label="Email" required placeholder=" " class="txt-field shrink"></v-text-field>
                                </v-col>
                                <v-spacer></v-spacer>
                            </v-row>
                            <v-row no-gutters>
                                <v-spacer></v-spacer>
                                <v-col cols="12" md="10" sm="12" xs="12">
                                    <v-text-field dense outlined  v-model="password" :rules="rules.password" label="Password" required
                                                  :prepend-inner-icon="'mdi-key'"
                                                  :append-icon="value ? 'mdi-eye' : 'mdi-eye-off'"
                                                  @click:append="() => (value = !value)"
                                                  :type="value ? 'password' : 'text'"
                                                  placeholder=" " class="txt-field shrink"
                                    >

                                    </v-text-field>
                                </v-col>
                                <v-spacer></v-spacer>
                            </v-row>
                            <v-row no-gutters>
                                <v-spacer></v-spacer>
                                <v-col cols="12" md="10" sm="12" xs="12" class="text-left">
                                    <span class="text-sm-subtitle-2">By continuing with any of the options below, you agree to the <a @click="tos">Terms of Service</a></span>
                                </v-col>
                                <v-spacer></v-spacer>
                            </v-row>
                            <v-row no-gutters justify="center" v-if="errorMsg">
                                <v-col cols="12" md="10" sm="12" xs="12" class="pl-2 pr-2">
                                    <v-alert text dense type="error">
                                        {{errorMsg}}
                                    </v-alert>
                                </v-col>
                            </v-row>
                            <v-row justify="center">
                                <v-col cols="12" md="9" sm="12" xs="12" class="pl-2 pr-2 text-center" v-if="loading">
                                    <v-progress-circular :size="30" color="secondary" indeterminate></v-progress-circular>
                                </v-col>
                                <v-col cols="12" md="10" sm="3" xs="6" v-else>
                                    <AppButton @click="onSignIn" :valid="isFormValid">Sign in</AppButton>
                                </v-col>
                            </v-row>
                        </v-form>
                        <v-row justify="center" class="text-center">
                            <div></div>
                        </v-row>
                        <v-row justify="center" class="text-center">
                            <v-col cols="12" md="6" sm="12" xs="12">
                                <div>
                                    <a class="font-weight-light blue-grey--text text-decoration-underline" @click="resetPassword">Forgot password?</a>
                                </div>
                            </v-col>
                            <v-col cols="12" md="6" sm="12" xs="12">
                                <div>
                                    <a class="font-weight-light blue-grey--text text-decoration-underline" @click="newAccount"> Create a new account</a>
                                </div>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import { mapActions } from 'vuex';
    import AppButton from "../../components/common/AppButton";

    export default {
        name: "SignIn",
        components: {
            AppButton
        },
        data(){
            return {
                isFormValid: false,
                email: '',
                rules: {
                    email: [
                        v => !!v || 'E-mail is required',
                        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
                    ],
                    password: [
                        v => !!v || 'Password is Required.',
                    ],
                },
                password: '',
                value: String,
                displayName: '',
                errorMsg: null,
                loading: false
            }
        },
        computed: {
            getBreakpoint(){
                return this.$vuetify.breakpoint.name == 'xs' ? false : true;
            }
        },
        methods: {
            ...mapActions({
                authenticate: 'authenticate'
            }),
            onSignIn(){
                this.loading = true;
                this.authenticate({email: this.email, password: this.password}).catch(err => {
                    console.log(err.response.data.error);
                    this.loading = false;
                    this.errorMsg = err.response.data.error;
                });
            },
            resetPassword(){
                console.log("reset password");
                // navigate to reset-password
                this.$router.push({ name: 'ResetPassword'});
            },
            newAccount(){
                this.$router.push({ name: 'NewAccount'});
            },
            tos(){
                this.$router.push({ name: 'TermsOfService'});
            },
        }
    }
</script>

<style scoped>

</style>