<template>
    <v-container fluid class="img">
        <v-row no-gutters justify="center" class="jumbo-title">
            <v-col cols="12" md="12" sm="12" xs="12">
                <span class="jumbo-title-font">Meals By The Day Pro: Weekly Meal Planner</span>
            </v-col>
        </v-row>
        <v-row no-gutters justify="center" class="jumbo-subtitle">
            <v-col cols="12" md="12" sm="12" xs="12">
                <span class="jumbo-subtitle-font">Take control of your diet with meal planning!</span>
            </v-col>
        </v-row>
        <v-row class="icons-padding">
            <a :href="'https://play.google.com/store/apps/details?id=com.mealsbytheday.mbdpro.prod'" target="_blank">
                <v-img height="70" contain max-width="150" src="@/assets/images/google-play-badge.png" />
            </a>
            <a :href="'https://apps.apple.com/us/app/meals-by-the-day-pro/id1540663292'" target="_blank">
                <v-img height="70"  contain max-width="150" src="@/assets/images/apple-store.svg" />
            </a>
            <a :href="'https://www.amazon.com/dp/B08QJMCY8L/?ref-suffix=ss_copy'" target="_blank" style="padding-left: .5em; padding-top: .2em" class="hidden-xs-only">
                <v-img height="65" contain max-width="150" src="@/assets/images/alexa.png" />
            </a>
        </v-row>
        <v-row class="icons-padding hidden-sm-and-up">
            <a :href="'https://www.amazon.com/dp/B08QJMCY8L/?ref-suffix=ss_copy'" target="_blank" style="padding-left: .5em">
                <v-img height="65" contain max-width="150" src="@/assets/images/alexa.png" />
            </a>
        </v-row>

    </v-container>
</template>

<script>
    export default {
        name: "Jumbotron"
    }
</script>

<style scoped>
    .jumbo-padding-google {
        padding-left: 10em;
    }
    .jumbo-padding-apple {
        padding-left: 5em;
    }
    .jumbo-title {
        margin-top: 10em;
        padding-left: 5em;
    }
    .jumbo-title-font {
        font-size: 2.5em;
    }
    .jumbo-subtitle {
        padding-left: 5em;
    }
    .jumbo-subtitle-font {
        font-size: 1.5em;
    }
    .icons-padding {
        padding-left: 5.25em;
    }

    @media screen and (max-width: 768px) {
        .jumbo-title {
            padding-left: 1em;
            margin-top: 1em;

        }
        .jumbo-subtitle {
            padding-left: 1em;

        }
        .jumbo-title-font {
            font-size: 2em;
        }

        .jumbo-subtitle-font {
            font-size: 1.25em;
        }

        .icons-padding {
            padding-left: 1em;
        }

    }
    .img {
        background:  linear-gradient(rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)),
        url('../assets/images/jumbo-unsplash.png');
        width: 100%;
        height: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        overflow: hidden;
        /*position: absolute;*/
        top: 0;
        left: 0;
        z-index: 1;
    }

</style>