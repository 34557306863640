<template>
    <v-container fluid>
        <v-row justify="center">
            <v-col cols="12" md="5" sm="8" xs="12">
                <v-card class="justify-center" flat tile :outlined="getBreakpoint">
                    <v-row justify="center" class="pt-3">
                        <v-avatar>
                            <v-img src="@/assets/logo.svg"></v-img>
                        </v-avatar>
                    </v-row>
                    <v-card-title class="justify-center text-h6">Create a new Account</v-card-title>
                    <v-card-text>
                        <v-form v-model="isFormValid">
                            <v-row no-gutters>
                                <v-spacer></v-spacer>
                                <v-col cols="12" md="10" sm="12" xs="12">
                                    <v-text-field dense outlined  v-model="displayName" :prepend-inner-icon="'mdi-account'" :rules="rules.displayName" label="Username" required placeholder=" " class="txt-field shrink"></v-text-field>
                                </v-col>
                                <v-spacer></v-spacer>
                            </v-row>
                            <v-row no-gutters>
                                <v-spacer></v-spacer>
                                <v-col cols="12" md="10" sm="12" xs="12">
                                    <v-text-field dense outlined :prepend-inner-icon="'mdi-email'"  v-model="email" :rules="rules.email" label="Email" required placeholder=" " class="txt-field shrink"></v-text-field>
                                </v-col>
                                <v-spacer></v-spacer>
                            </v-row>
                            <v-row no-gutters>
                                <v-spacer></v-spacer>
                                <v-col cols="12" md="10" sm="12" xs="12">
                                    <v-text-field dense outlined  v-model="password" :rules="rules.password" label="" required
                                                  :prepend-inner-icon="'mdi-key'"
                                                  :append-icon="value ? 'mdi-eye' : 'mdi-eye-off'"
                                                  @click:append="() => (value = !value)"
                                                  :type="value ? 'password' : 'text'"
                                                  placeholder="Password" class="txt-field shrink"></v-text-field>
                                </v-col>
                                <v-spacer></v-spacer>
                            </v-row>
                            <v-row no-gutters>
                                <v-spacer></v-spacer>
                                <v-col cols="12" md="10" sm="12" xs="12" class="text-left">
                                    <v-checkbox v-model="checkbox" :rules="rules.checkbox" required>
                                        <template #label>
                                            <span class="text-sm-subtitle-2">By continuing with any of the options below, you agree to the <a @click="tos">Terms of Service</a></span>
                                        </template>
                                    </v-checkbox>
                                </v-col>
                                <v-spacer></v-spacer>
                            </v-row>
                            <v-row no-gutters wrap justify="center" v-if="errorMsg">
                                <v-col cols="12" md="10" sm="12" xs="12" class="pl-2 pr-2 pt-3">
                                    <v-alert text dense type="error">
                                        {{errorMsg}}
                                    </v-alert>
                                </v-col>
                            </v-row>
                            <v-row justify="center">
                                <v-col cols="12" md="10" sm="12" xs="12" class="pl-2 pr-2 text-center" v-if="loading">
                                    <v-progress-circular :size="30" color="secondary" indeterminate></v-progress-circular>
                                </v-col>
                                <v-col cols="12" md="10" sm="10" xs="12" class="mt-2" v-else>
                                    <AppButton @click="onButtonClick" :valid="isFormValid">Sign up</AppButton>
                                </v-col>
                            </v-row>
                            <v-row justify="center" class="text-center">
                                <v-col cols="12" md="12" sm="12" xs="12">
                                    <div>
                                        <span class="font-weight-light blue-grey--text text-decoration-none">Already have an account? <a class="font-weight-light text-decoration-underline" @click="signIn"> Sign In</a></span>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import account from '../../api/account';
    import AppButton from "../../components/common/AppButton";

    export default {
        name: "AccountNew",
        components: {
            AppButton
        },
        data(){
            return {
                isFormValid: false,
                email: '',
                rules: {
                    displayName: [
                        v => !!v || 'Required.',
                        v => (v && v.length <= 20 && v.length >= 4) || 'Username must be at least than 4 characters'
                    ],
                    email: [
                        v => !!v || 'E-mail is required',
                        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
                    ],
                    password: [
                        v => !!v || 'Required.',
                        v => (v && v.length <= 20 && v.length >= 8) || 'Password must be at least 8 characters and contain at least one uppercase character.'
                    ],
                    checkbox:[
                        v => !!v || 'Agreement required',
                    ]
                },
                password: '',
                value: String,
                displayName: '',
                errorMsg: null,
                loading: false,
                checkbox: false,
            }
        },
        computed: {
            getBreakpoint(){
                return this.$vuetify.breakpoint.name == 'xs' ? false : true;
            }
        },
        methods: {
            onButtonClick(){
                console.log(this.checkbox);
                this.loading = true;
                account.signUp(this.displayName, this.email, this.password).then( res => {
                    console.log(res);
                    this.loading = false;
                    // navigate to verify page
                    this.$router.push({ name: 'VerifyAccount', params: { sub: this.email } });
                }).catch(err => {
                    console.log(err.response.data.error);
                    this.loading = false;
                    this.errorMsg = err.response.data.error;
                });

            },
            signIn(){
                this.$router.push({ name: 'SignIn'});
            },
            tos(){
                this.$router.push({ name: 'TermsOfService'});
            },
        }
    }
</script>

<style scoped>
</style>