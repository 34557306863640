<template>
    <v-container fluid>
        <v-row justify="center">
            <v-col cols="12" md="5" sm="8" xs="12">
                <v-card tile flat :outlined="getBreakpoint">
                    <v-row justify="center" class="pt-3">
                        <v-avatar>
                            <v-img src="@/assets/logo.svg"></v-img>
                        </v-avatar>
                    </v-row>
                    <v-card-title class="justify-center text-h6"> Create a new password</v-card-title>
                    <v-row no-gutters wrap justify="center" v-if="updateComplete">
                        <v-col cols="12" md="9" sm="12" xs="12" class="pl-2 pr-2">
                            <v-alert text prominent type="success">
                                Password has been updated.
                            </v-alert>
                        </v-col>
                    </v-row>
                    <v-card-text v-else>
                        <v-row no-gutters justify="center" wrap>
                            <v-spacer></v-spacer>
                            <v-col cols="12" md="10" sm="12" xs="12" class="pt-5 pr-2 pl-2">
                                <v-label>Create a new password for your account.</v-label>
                            </v-col>
                            <v-spacer></v-spacer>
                        </v-row>
                        <v-row class="pt-5" wrap no-gutters>
                            <v-spacer></v-spacer>
                            <v-col cols="12" md="10" sm="12" xs="12">
                                <v-form v-model="isValid">
                                    <v-text-field dense outlined  v-model="password" :rules="passwordRules" label="New Password" required placeholder=" " type="password" class="txt-field shrink"></v-text-field>
                                </v-form>
                            </v-col>
                            <v-spacer></v-spacer>
                        </v-row>
                        <v-row no-gutters wrap justify="center" v-if="msg">
                            <v-col cols="12" md="10" sm="12" xs="12">
                                <v-alert text prominent type="error">
                                    {{msg}}
                                </v-alert>
                            </v-col>
                        </v-row>
                        <v-row justify="center">
                            <v-col cols="12" md="10" sm="12" xs="12" class="text-center" v-if="loading">
                                <v-progress-circular :size="30" color="secondary" indeterminate></v-progress-circular>
                            </v-col>
                            <v-col cols="12" md="10" sm="12" xs="12" v-else>
                                <v-btn @click="OnCreateNewPassword" color="primary" block :disabled="!isValid">Submit</v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import account from "../../api/account";
    export default {
        name: "PasswordUpdate",
        props: {
            sub: {
                type: String,
                required: true
            },
            code: {
                type: String,
                required: true,
            }
        },
        data(){
            return {
                isValid: false,
                password: '',
                passwordRules: [
                    v => !!v || 'Required.',
                    v => (v && v.length <= 20 && v.length >= 8) || 'Password must be at least 8 characters. Must contain at least one uppercase letter.'
                ],
                msg: null,
                loading: false,
                updateComplete: false,
            }
        },
        computed: {
            getBreakpoint(){
                return this.$vuetify.breakpoint.name == 'xs' ? false : true;
            }
        },
        methods: {
            OnCreateNewPassword(){
                this.loading = true;
                account.resetPassword(this.sub, this.password, this.code).then(res => {
                    console.log(res);
                    this.loading = false;
                    this.updateComplete = true;
                    this.msg = null;
                }).catch(err => {
                    console.log(err.response.data.error);
                    this.msg = err.response.data.error;
                    this.loading = false;
                })
            },
        }
    }
</script>

<style scoped>

</style>