import Vue from 'vue'
import VueRouter from 'vue-router'
import External from '@/views/External'
import AccountNew from "@/views/External/AccountNew";
import PasswordReset from "@/views/External/PasswordReset";
import AccountNewCode from "@/views/External/AccountNewCode";
import AccountVerify from "@/views/External/AccountVerify";
import PasswordUpdate from "@/views/External/PasswordUpdate";
import CloseAccount from "@/views/External/CloseAccount";
import AccountHome from '@/views/AccountHome';
import store from '@/store';
import SignIn from "../views/External/SignIn";
import ExternalHome from "../views/External/ExternalHome";
import PrivacyPolicy from "../views/External/PrivacyPolicy";
import TermsOfService from "../views/External/TermsOfService";

Vue.use(VueRouter)

const routes = [
  {
    path: '/home',
    name: 'Home',
    component: AccountHome,
    meta: { requiresAuth: true},
  },
  {
    path: '',
    name: 'External',
    component: External,
    children: [
      {
        path: '/signin',
        name: 'SignIn',
        component: SignIn,
        meta: { requiresNoAuth: true},
      },
      {
        path: '/privacy',
        name: 'Privacy',
        component: PrivacyPolicy,
      },
      {
        path: '/tos',
        name: 'TermsOfService',
        component: TermsOfService,
      },
      {
        path: '/new-account',
        name: 'NewAccount',
        component: AccountNew,
        meta: { requiresNoAuth: true},
      },
      {
        path: 'reset-password',
        name: 'ResetPassword',
        component: PasswordReset,
        meta: { requiresNoAuth: true},
        beforeEnter(to, from, next) {
          if (to.query && to.query.sub && to.query.code) {
            // if email link redirect to route below
            next({ name: 'NewPassword', params: {sub: to.query.sub, code: to.query.code} })
          } else
            next()
        }
      },
      {
        path: "reset-password",
        name: "NewPassword",
        component: PasswordUpdate,
        meta: { requiresNoAuth: true},
        props: true
      },
      {
        path: 'verify',
        name: 'VerifyAccount',
        component: AccountNewCode,
        props: true,
        beforeEnter(to, from, next) {
          if (to.query && to.query.sub && to.query.code) {
            // if email link redirect to route below
            next({ name: 'ConfirmAccount', params: {sub: to.query.sub, code: to.query.code} });
          }else if(!to.params || !to.params.sub){
            //must have sub param (email) else route back to home
            next({ name: ''});
          }else
            next()
        }
      },
      {
        path: 'verify',
        name: 'ConfirmAccount',
        component: AccountVerify,
        meta: { requiresNoAuth: true},
        props: true
      },
      {
        path: 'account/close',
        name: 'CloseAccount',
        component: CloseAccount,
        meta: { requiresNoAuth: true},
        beforeEnter(to, from, next) {
          if (to.query && to.query.token) {
            // if email link
            next();
          } else
            next({name: ''});
        }
      },
      {
        // will match everything
        path: '*',
        component: ExternalHome,
        meta: { requiresNoAuth: true},
      }
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

// auth guard
router.beforeEach((to, from, next) => {
  if(to.meta.requiresAuth){
    if(store.getters.isAuthenticated){
      next();
    }else{
      next({ name: 'SignIn'}); //redirect back to unauthenticated home page
    }
  } if(to.meta.requiresNoAuth){
    if(!store.getters.isAuthenticated){
      next();
    }else{
      next({ name: 'Home'}); //redirect back to authenticated home page
    }
  } else{
    next();
  }
});


export default router
