<template>
  <router-view :key="$route.path"/>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'External',
  components: {
  }
}
</script>
<style scoped>

</style>
