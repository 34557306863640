import account from "../../api/account";
import router from '@/router/index';
const MBTD_ACCESS_TOKEN = "MBTD_Access_Token";

const state = {
    accessToken: window.localStorage.getItem(MBTD_ACCESS_TOKEN),
    refreshToken: null,
    username: null,
};

const getters= {
    isAuthenticated(state){
        return !!state.accessToken;
    }
};

const actions = {
    authenticate({commit}, {email, password}){
        return new Promise((resolve, reject) => {
            account.authenticate(email, password).then(res => {
                const accessToken = res.data.accessToken;
                const refreshToken = res.data.refreshToken;
                commit('setTokens', {accessToken, refreshToken});
                window.localStorage.setItem(MBTD_ACCESS_TOKEN, accessToken);
                router.push({name: 'Home'});
                resolve();
            }).catch(err =>{
                router.push({name: ''});
                reject(err);
            })
        })
    },

    logout({commit}){
        return new Promise((resolve) => {
           commit('setTokens', {accessToken: null, refreshToken: null});
            window.localStorage.removeItem(MBTD_ACCESS_TOKEN);
            router.push({name: 'SignIn'});
           resolve();
        });
    }
};

const mutations = {
    setTokens(state, {accessToken, refreshToken}){
        state.accessToken = accessToken;
        state.refreshToken = refreshToken;
    }
};

export default {
    state,
    getters,
    actions,
    mutations,
}