const axios = require('axios');
const ACCOUNTS = 'accounts';
const BASE_URL = `${process.env.VUE_APP_API}/${ACCOUNTS}`;
const VERIFY = 'verify';
const RESEND_VERIFY = 'resend-verify';
const INIT_RESET_PASSWORD = 'init-reset-password';
const RESET_PASSWORD = 'reset-password';
const CLOSE = 'account-close';
const AUTHENTICATE = 'authenticate';


export default {
    signUp(displayName, email, password){
        const url = `${BASE_URL}`;
        return axios.post(url, {
            displayName: displayName,
            email: email,
            password: password
        })
    },
    verify(email, code){
        const url = `${BASE_URL}/${VERIFY}`;
        return axios.post(url, {
            email: email,
            code: code
        })
    },
    resendVerify(email){
        const url = `${BASE_URL}/${RESEND_VERIFY}`;
        return axios.post(url, {
            email: email
        })
    },
    initResetPassword(email){
        const url = `${BASE_URL}/${INIT_RESET_PASSWORD}`;
        return axios.post(url, {
            email: email
        })
    },
    resetPassword(email, password, code){
        const url = `${BASE_URL}/${RESET_PASSWORD}`;
        return axios.post(url, {
            email: email,
            password: password,
            code: code
        })
    },
    closeAccount(token){
        const url = `${BASE_URL}/${CLOSE}`;
        return axios.post(url, {
            token: token
        })
    },
    authenticate(email, password){
        const url = `${BASE_URL}/${AUTHENTICATE}`;
        return axios.post(url, {
            email: email,
            password: password,
        })
    },
}