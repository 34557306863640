<template>
    <v-container fluid>
        <v-row justify="center">
            <v-col cols="12" md="5" sm="8" xs="12">
                <v-card flat tile :outlined="getBreakpoint">
                    <v-row justify="center" class="pt-3">
                        <v-avatar>
                            <v-img src="@/assets/logo.svg"></v-img>
                        </v-avatar>
                    </v-row>
                    <v-card-title class="justify-center text-h6">Close Account</v-card-title>
                    <v-card-text>
                        <v-row no-gutters wrap justify="center" v-if="loading">
                            <v-col cols="12" md="9" sm="12" xs="12" class="pl-2 pr-2 text-center">
                                <v-progress-circular :size="30" color="secondary" indeterminate></v-progress-circular>
                            </v-col>
                        </v-row>
                        <v-row no-gutters wrap justify="center" v-if="!accountClosed && !loading">
                            <v-col cols="12" md="10" sm="12" xs="12" class="">
                                <v-alert text dense type="error">
                                    {{errorMsg}}
                                </v-alert>
                            </v-col>
                        </v-row>
                        <v-row no-gutters wrap justify="center" v-if="accountClosed && !loading">
                            <v-col cols="12" md="9" sm="12" xs="12" class="pl-2 pr-2">
                                <v-alert text prominent type="error">
                                    Account Closed.
                                </v-alert>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import account from "../../api/account";

    export default {
        name: "CloseAccount",
        mounted: function() {
            account.closeAccount(this.$route.query.token).then(res => {
                console.log(`Account closed: ${res}`);
                this.accountClosed = true;
                this.loading = false;
            }).catch(err => {
                console.log(err.response.data);
                this.errorMsg = err.response.data.error;
                this.accountClosed = false;
                this.loading = false;
            })
        },
        computed: {
            getBreakpoint(){
                return this.$vuetify.breakpoint.name == 'xs' ? false : true;
            }
        },
        data(){
            return {
                accountClosed: false,
                errorMsg: null,
                loading: true,
            }
        },
        methods: {

        }
    }
</script>

<style scoped>

</style>