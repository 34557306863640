<template>
    <v-container fluid>
        <v-row justify="center">
            <v-col cols="12" md="5" sm="8" xs="12">
                <v-card flat tile :outlined="getBreakpoint">
                    <v-row justify="center" class="pt-3">
                        <v-avatar>
                            <v-img src="@/assets/logo.svg"></v-img>
                        </v-avatar>
                    </v-row>
                    <v-card-title class="justify-center text-h6">Please verify your email</v-card-title>
                    <v-card-text>
                        <v-row no-gutters justify="center" class="text-center" wrap>
                            <v-spacer></v-spacer>
                            <v-col cols="12" md="10" sm="12" xs="12">
                                <v-label>Enter the code in the email sent to {{sub}}</v-label>
                            </v-col>
                            <v-spacer></v-spacer>
                        </v-row>
                        <v-row no-gutters class="pt-5" wrap>
                            <v-spacer></v-spacer>
                            <v-col cols="12" md="10" sm="12" xs="12">
                                <v-text-field dense outlined  v-model="code" :rules="codeRules" label="Verification Code" required placeholder=" " class="txt-field shrink"></v-text-field>
                            </v-col>
                            <v-spacer></v-spacer>
                        </v-row>
                        <v-row no-gutters wrap justify="center" v-if="errorMsg">
                            <v-col cols="12" md="10" sm="12" xs="12">
                                <v-alert text dense type="error">
                                    {{errorMsg}}
                                </v-alert>
                            </v-col>
                        </v-row>
                        <v-row justify="center">
                            <v-col cols="12" md="10" sm="12" xs="12" class="pl-2 pr-2 text-center" v-if="loading">
                                <v-progress-circular :size="30" color="secondary" indeterminate></v-progress-circular>
                            </v-col>
                            <v-col cols="12" md="10" sm="10" xs="12" class="mt-2" v-else>
                                <v-btn @click="onVerify" color="primary" block :disabled="code.length < 5">Continue</v-btn>
                            </v-col>
                        </v-row>
                        <v-row no-gutters justify="center" v-if="!emailSent">
                            <v-col cols="12" md="10" sm="12" xs="12">
                                <a class="blue-grey--text text-decoration-underline" @click="onResendEmail">Resend Email</a>
                            </v-col>
                        </v-row>
                        <v-row no-gutters justify="center" v-else>
                            <v-col cols="12" md="10" sm="12" xs="12">
                                <span class="blue-grey--text">Email Sent.</span>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import account from "../../api/account";
    export default {
        name: "AccountNewCode",
        props: {
            sub: {
                type: String,
                required: true
            }
        },
        data(){
            return {
                code: '',
                codeRules: [
                    v => !!v || 'Required.'],
                errorMsg: null,
                emailSent: false,
                loading: false
            }
        },
        computed: {
            getBreakpoint(){
                return this.$vuetify.breakpoint.name == 'xs' ? false : true;
            }
        },
        methods: {
            onVerify(){
                // navigate to confirm page to do the api call
                this.$router.push({
                    name: 'ConfirmAccount',
                    params: {sub: this.sub, code: this.code}
                });
            },
            onResendEmail(){
                this.loading = true;
                account.resendVerify(this.sub).then(res => {
                    // only allow to resend once?
                    console.log(res);
                    this.emailSent = true;
                    this.loading = false;
                }).catch(err => {
                    console.log(err.response);
                    this.errorMsg = err.response.data.error;
                    this.loading = false;
                })
            }
        }
    }
</script>

<style scoped>
</style>