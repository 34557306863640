<template>
    <v-card flat>
        <v-card-title>
            Welcome.
        </v-card-title>
    </v-card>
</template>

<script>
    export default {
        name: "AccountHome"
    }
</script>

<style scoped>

</style>