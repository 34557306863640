<template>
    <v-container fluid>
        <v-row justify="start">
            <v-spacer/>
            <v-col cols="12" md="8" sm="10" xs="10">
                <span class="text-h4 title"> Privacy Policy</span>
                <p>Last Updated: November 30, 2020. </p>
            </v-col>
            <v-spacer/>
        </v-row>
        <v-row justify="start">
            <v-spacer/>
            <v-col cols="12" md="8" sm="10" xs="10">
                <p>The following is the Privacy Policy of Kombubrew LLC (“Kombubrew”, “we”, “our”, or “us”), which
                    applies to information that we collect when you use our website (including
                    mealsbytheday.com) and our mobile applications and related services (collectively referred to as
                    “Services”).</p>
                <p>By accessing or using our Services, you agree to the collection and use of information in relation to
                    this policy. If you do not accept this Privacy Policy, you must not access or use our Services.</p>

                <h4 class="text-h5 title">Information We Collect</h4>
                <p>
                    We collect information about you when you provide information to us, automatically
                    through operating our Services, and from outside sources.
                </p>
                <h5 class="text-h6 title">Information You Provide to Us</h5>
                <ol>
                    <li>
                        <strong>Basic Account Information</strong>: We ask for basic information from you in order to
                        set up your account. For example, we require individuals who sign up for an account with our
                        Services to provide an email address, a display name and password. You may provide us with more
                        information but we don’t require that information to create an account with our Services.
                    </li>
                    <li>
                        <strong>Content Information</strong>: You may also provide us with information about
                        you in published content. For example, if you post content that includes biographic
                        information about you in the description, we will have that information.
                    </li>
                    <li>
                        <strong>Communications With Us</strong>: You may also provide us information when you
                        respond to surveys, communicate with our Support Team about a support question, post a
                        question or provide us with feedback.
                    </li>
                </ol>
                <h5 class="text-h6 title">Information We Collect Automatically</h5>
                <ol>
                    <li>
                        <strong>Log Information</strong>: We collect information that web browsers, mobile
                        devices, and servers typically make available, such as the browser type, IP address,
                        unique device identifiers, language preference, referring site, the date and time of
                        access, operating system, and mobile network information. We collect log information
                        when you use our Services&ndash;for example, when you create or make changes to your
                        Meal plans on our services.
                    </li>
                    <li>
                        <strong>Usage Information</strong>: We collect information about your usage of our
                        Services. For example, we collect information about the actions that users perform
                        &ndash;in other words, who did what, when and to what thing on a site. We also collect
                        information about what happens when you use our Services (e.g., page views and
                        interactions with our Services) along with information about your device (e.g., screen
                        size, name of cellular network, and mobile device manufacturer). We use this
                        information to, for example, provide our Services to you, as well as get insights on
                        how people use our Services, so we can make our Services better.
                    </li>
                    <li>
                        <strong>Location Information</strong>: We may determine the approximate location of
                        your device from your IP address. We collect and use this information to, for example,
                        calculate how many people visit our Services from certain geographic regions. We may
                        also collect information about your precise location via our mobile apps (when, for
                        example, you post a photograph with location information) if you allow us to do so
                        through your mobile device operating system&rsquo;s permissions.
                    </li>
                    <li>
                        <strong>Stored Information:</strong> We may access information stored on your mobile
                        device via our mobile app. We access this stored information through your device
                        operating system&rsquo;s permissions. For example, if you give us permission to access
                        the photographs on your mobile device&rsquo;s camera roll, our Services may access the
                        photos stored on your device when you upload a photograph of your Meal.
                    </li>
                    <li>
                        <strong>Information from Cookies &amp; Other Technologies</strong>: A cookie is a
                        string of information that a website stores on a visitor&rsquo;s computer, and that
                        the visitor&rsquo;s browser provides to the website each time the visitor returns.
                        Pixel tags (also called web beacons) are small blocks of code placed on websites and
                        emails. We use cookies and other technologies like pixel tags to help us identify and
                        track visitors, usage, and access preferences for our Services.
                    </li>
                </ol>
                <h5 class="text-h6 title">Information We Collect from Other Sources</h5>
                <p>
                    We may also get information about you from other sources. For example, if you create an
                    account with our Services or log into to our Services through another service (like
                    Google), we will receive information from that service (such as your username and basic
                    profile information) via the authorization procedures used by that service. The
                    information we receive depends on which services you authorize and any options that are
                    available.
                </p>
                <h4 class="text-h5 title">
                    How And Why We Use Information
                    <strong>&nbsp;</strong>
                </h4>
                <h5 class="text-h6 title">Purposes for Using Information</h5>
                <p>We use information about you as mentioned above and for the purposes listed below:</p>
                <ol>
                    <li>
                        To provide our Services&ndash;for example, to set up and maintain your account, and
                        backup and restore your content.
                    </li>
                    <li>
                        To further develop and improve our Services&ndash;for example by adding new features
                        that we think our users will enjoy or will help them to create and manage their meal
                        plans more efficiently;
                    </li>
                    <li>
                        To monitor and analyze trends and better understand how users interact with our
                        Services, which helps us improve our Services and make them easier to use;
                    </li>
                    <li>
                        To monitor and prevent any problems with our Services, protect the security of our
                        Services, detect and prevent fraudulent transactions and other illegal activities,
                        fight spam, and protect the rights and property of Kombubrew and others, which may
                        result in us declining a transaction or the use of our Services;
                    </li>
                    <li>
                        To communicate with you, for example through an email, about offers and promotions we
                        think will be of interest to you, solicit your feedback, or keep you up to date on
                        Kombubrew and our products; and
                    </li>
                    <li>
                        To personalize your experience using our Services, provide content recommendations,
                        and target our marketing messages to groups of our users (for example, those who have
                        been our user for a certain length of time).
                    </li>
                </ol>
                <h5 class="text-h6 title">Legal Bases for Collecting and Using Information</h5>
                <p>Our use of your information is based on the grounds that:</p>
                <ol>
                    <li>
                        The use is necessary in order to fulfill our commitments to you under our Terms of
                        Service or other agreements with you or is necessary to administer your
                        account&ndash;for example, in order to enable access to our Services; or
                    </li>
                    <li>The use is necessary for compliance with a legal obligation; or</li>
                    <li>
                        The use is necessary in order to protect your vital interests or those of another
                        person; or
                    </li>
                    <li>
                        We have a legitimate interest in using your information&ndash;for example, to provide
                        and update our Services, to improve our Services so that we can offer you an even
                        better user experience, to safeguard our Services, to communicate with you, to
                        measure, gauge, and improve the effectiveness of our advertising, and better
                        understand user retention and attrition, to monitor and prevent any problems with our
                        Services, and to personalize your experience; or
                    </li>
                    <li>
                        You have given us your consent&ndash;for example, by registering for an account or
                        continuing to access our Services.
                    </li>
                </ol>
                <h4 class="text-h5 title">
                    Sharing Information
                    <strong>&nbsp;</strong>
                </h4>
                <h5 class="text-h6 title">How We Share Information</h5>
                <p>
                    We do not sell our users&rsquo; private personal information. We share information about
                    you in the limited circumstances spelled out below and with appropriate safeguards on
                    your privacy:
                </p>
                <ol>
                    <li>
                        <strong>Subsidiaries, Employees, and Independent Contractors</strong>: We may disclose
                        information about you to our subsidiaries, our employees, and individuals who are our
                        independent contractors that need to know the information in order to help us provide
                        our Services or to process the information on our behalf.
                    </li>
                    <li>
                        <strong>Third Party Vendors</strong>: We may share information about you with third
                        party vendors who need to know information about you in order to provide their
                        services to us. This group includes vendors that help us provide our Services to you,
                        those that assist us with our marketing efforts, and those that help us understand and
                        enhance our Services. Please see the &ldquo;Third Party Services We May Use&rdquo;
                        section to read more about the Services we may employ.
                    </li>
                    <li>
                        <strong>Legal Requests</strong>: We may disclose information about you in response to
                        a subpoena, court order, or other governmental request.
                    </li>
                    <li>
                        <strong>To Protect Rights, Property, and Others</strong>: We may disclose information
                        about you when we believe in good faith that disclosure is reasonably necessary to
                        protect the property or rights of Kombubrew, third parties, or the public at large.
                    </li>
                    <li>
                        <strong>Business Transfers</strong>: In connection with any merger, sale of company
                        assets, or acquisition of all or a portion of our business by another company, or in
                        the event that Kombubrew goes out of business or enters bankruptcy, user information
                        would likely be one of the assets that is transferred or acquired by a third party. If
                        any of these events were to happen, this Privacy Policy would continue to apply to
                        your information and the party receiving your information may continue to use your
                        information, but only consistent with this Privacy Policy.
                    </li>
                    <li>
                        <strong>With Your Consent</strong>: We may share and disclose information with your
                        consent or at your direction. For example, we may share your information with third
                        parties with which you authorize us to do so.
                    </li>
                    <li>
                        <strong>Aggregated or De-Identified Information</strong>: We may share information
                        that has been aggregated or reasonably de-identified, so that the information could
                        not reasonably be used to identify you. For instance, we may publish aggregate
                        statistics about the use of our Services.
                    </li>
                    <li>
                        <strong>Published Support Requests</strong>: If you send us a request (for example,
                        via a support email or one of our feedback mechanisms), we reserve the right to
                        publish that request in order to help us clarify or respond to your request or to help
                        us support other users.
                    </li>
                </ol>
                <h5 class="text-h6 title">Information Shared Publicly</h5>
                <p>We do not publicly disclose your information, with the following exceptions:</p>
                <ol>
                    <li>Information that you choose to make public is disclosed publicly.</li>
                    <li>
                        User Uploads: We employ specific URL schemes to provide you access to the content you upload.
                        This means that anyone who knows this generated URL can access the uploaded resource. For
                        example, if you upload a personally identifiable photograph, this photograph is accessible to,
                        for example, anyone with whom you share the URL.
                    </li>
                </ol>
                <h4 class="text-h5 title">How Long We Keep Information</h4>
                <p>
                    We generally discard information about you when we no longer need the information for the purposes
                    for which we collect and use it–which are described in the section above on “How and Why We Use
                    Information”–and we are not legally required to continue to keep it. For example, we generally keep
                    the web server logs that record information about users of our Services, such as the user’s IP
                    address, browser type, and operating system, for up to 90 days. We retain the logs for this period
                    of time in order to, among other things, analyze traffic and investigate issues if something goes
                    wrong with our Services.
                    <strong>&nbsp;</strong>
                </p>
                <h4 class="text-h5 title">Security</h4>
                <p>
                    We take reasonable measures to protect information about you against unauthorized
                    access, use, alteration, or destruction, such as monitoring our Services for potential
                    vulnerabilities and attacks. But remember that no method of transmission over the
                    internet, or method of electronic storage is 100% secure and reliable, and we cannot
                    guarantee its absolute security.
                    <strong>&nbsp;</strong>
                </p>
                <h4 class="text-h5 title">Choices</h4>
                <p>You have several choices available when it comes to information about you:</p>
                <ol>
                    <li>
                        <strong>Limit the Information that You Provide</strong>: If you have an account with
                        us, you can choose not to provide the optional account information. Please keep in
                        mind that if you do not provide this information, certain features of our Services may
                        not be accessible.
                    </li>
                    <li>
                        <strong>Limit Access to Information On Your Mobile Device</strong>: Your mobile device
                        operating system should provide you with the ability to discontinue our ability to
                        collect stored information or location information via our mobile app. If you do so,
                        you may not be able to use certain features (like adding a photograph of your Meal,
                        for example).
                    </li>
                    <li>
                        <strong>Opt-Out of Electronic Communications</strong>: You may opt out of receiving
                        promotional messages from us. Just follow the instructions in those messages. If you
                        opt out of promotional messages, we may still send you other messages, like those
                        about your account and legal notices.
                    </li>
                    <li>
                        <strong>Set Your Browser to Reject Cookies</strong>: At this time, Kombubrew does not
                        respond to &ldquo;do not track&rdquo; signals across our Services. However, you can
                        usually choose to set your browser to remove or reject browser cookies before using
                        our website, with the drawback that certain features of our website may not function
                        properly without the aid of cookies.
                    </li>
                    <li>
                        <strong>Close Your Account</strong>: If you no longer want to use our Services, you
                        can close your account. Please keep in mind that we may continue to retain your
                        information after closing your account, as described in the &ldquo;How Long We Keep
                        Information&rdquo; section above&ndash;for example, when that information is
                        reasonably needed for our legitimate business interests.
                        <strong>&nbsp;</strong>
                    </li>
                </ol>
                <h4 class="text-h5 title">Your Rights</h4>
                <p>
                    If you have questions about how to access, correct, or delete your data, you can reach
                    out to us via the &ldquo;Contact Us&rdquo; section. Note that&nbsp;Kombubrew does not
                    disclose personal information to third parties for their direct marketing purposes.
                </p>
                <h4 class="text-h5 title">Transferring Information</h4>
                <p>
                    By accessing or using our Services or otherwise providing information to us, you consent
                    to the processing, transfer, and storage of information in and to the U.S. and other
                    countries, which may have rights and protections that are different from those in your
                    home country.
                </p>
                <h4 class="text-h5 title">Third Party Services We May Use</h4>
                <p>
                    We may use other third party services to provide our Services or make our Services
                    better.&nbsp;Note that this Privacy Policy only covers the collection of information by
                    Kombubrew and does not cover the collection of information by any third party
                    services.&nbsp; Kombubrew uses third party services, including:
                </p>

                <ol>
                    <li><strong>Sendgrid</strong>: We use sendgrid to communicate with you, such as send you emails. You
                        can learn more
                        about
                        how it collects and processes data by visiting
                        <a href="https://sendgrid.com/policies/privacy/services-privacy-policy">https://sendgrid.com/policies/privacy/services-privacy-policy</a>.
                    </li>
                    <li><strong>Firebase</strong>: We use Firebase to help us provide our services, including: Google
                        Analytics, Google
                        Analytics for Firebase, Firebase Authentication, Firebase Cloud Storage, Firebase Cloud
                        Functions,
                        Firebase Cloud Hosting. You can learn more about how Firebase collects and processes data by
                        visiting <a href="https://policies.google.com/privacy/partners">https://policies.google.com/privacy/partners</a>.
                        You can opt out of Google Analytics by
                        visiting <a href="https://tools.google.com/dlpage/gaoptout">https://tools.google.com/dlpage/gaoptout</a>.
                    </li>
                    <li><strong>AWS</strong>: We use AWS to help us provide our services. You can learn more about how
                        AWS collects and
                        processes data by visiting <a href="https://aws.amazon.com/privacy/">https://aws.amazon.com/privacy/</a>.
                    </li>
                    <li><strong>RevenueCat</strong>: We use RevenueCat to manage in-app purchases as well as aspects of
                        our products and
                        services. You can learn more about how RevenueCat collects and processes data by visiting
                        <a href="https://www.revenuecat.com/privacy">https://www.revenuecat.com/privacy</a>.
                    </li>
                </ol>
                <h4 class="text-h5 title">Links to Other Sites</h4>
                <p>
                    Our Services may contain links to other sites. If you click on a third-party link, you
                    will be directed to that site. These external sites are not operated by us. We have no
                    control over and assume no responsibility for the content, privacy policies, or
                    practices of any third-party sites or services.
                </p>
                <h4 class="text-h5 title">Children&rsquo;s Privacy</h4>
                <p>
                    Our Services are not directed at persons under the age of 18. We do not knowingly collect personally
                    identifiable information from such persons or knowingly allow such persons to register for an
                    account. If we become aware that we have collected personal information from such persons, we take
                    steps to delete that information. If you are a parent or guardian and you are aware that your child
                    has provided us with personal information, you can contact us so that we can take steps to delete
                    that information.

                </p>
                <h4 class="text-h5 title">Privacy Policy Changes</h4>
                <p>
                    Kombubrew may change our Privacy Policy from time to time. Changes to this Privacy Policy are
                    effective when they are posted on this page. Your continued use of the Services after any change in
                    this Privacy Policy will constitute your consent to such change.
                </p>
                <h4 class="text-h5 title">Contact Us</h4>
                <p>
                    If you have a question about this Privacy Policy, please email us at
                    privacy@mealsbytheday.com.
                </p>
            </v-col>
            <v-spacer/>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        name: "PrivacyPolicy"
    }
</script>

<style scoped>
    .title {
        font-weight: 500;
    }
</style>