<template>
    <v-container fluid fill-height class="px-0 py-0">
        <v-row class="my-0 py-0 jumbo-container" no-gutters>
            <Jumbotron class="jumbo"/>
        </v-row>
        <v-row class="banner-container">
            <LeftBanner :src="'plan-your-meals.png'">
                <template #title>
                    Plan your meals with ease
                </template>
                <template #body1>
                    Meals By The Day Pro makes planning meals quick and simple. Just tap on any day to start meal planning. Create a new meal, select from existing meals, or reschedule a meal you've already planned.
                    Then, review the week to make sure you are eating only the things you want to be eating!
                </template>
                <template #body2>
                    Like to plan your meals ahead? No problem!
                    Easily meal plan for next week, or even the month after, and quickly browse prior weeks to review your eating habits.
                    Stay on target and take control of your diet today!
                </template>
            </LeftBanner>
        </v-row>
        <v-row class="banner-container">
            <RightBanner :src="'meal-details.png'">
                <template #title>
                    Build an impressive recipe collection
                </template>
                <template #body1>
                    Tailor your recipe the way you like it. Manage all aspect of your custom recipe with tags, instructions, image, and ingredients.
                    Meal plan faster than ever with your favorites list or filter your recipes using tags to find the meals you want.
                </template>
            </RightBanner>
        </v-row>
        <v-row class="banner-container">
            <LeftBanner :src="'shopping-list.png'">
                <template #title>
                    Simple, powerful shopping list
                </template>
                <template #body1>
                    Eliminate waste with targeted shopping and save money. Assign stores and categories to your ingredients.
                    When you assign ingredients to a meal, our smart shopping list will organize the ingredients into the associated stores and categories.
                </template>
                <template #body2>
                    See associated meals that generate a particular shopping list item and easily share your shopping list with friends and family.
                </template>
            </LeftBanner>
        </v-row>
        <v-row class="banner-container">
            <RightBanner :src="'pantry.png'">
                <template #title>
                    Manage pantry items
                </template>
                <template #body1>
                    Manage quantity in your pantry, and assign default measurement, categories and store to allow you to track what you have on hand and only buy what you need.
                </template>
            </RightBanner>
        </v-row>
    </v-container>
</template>

<script>

    import Jumbotron from "../../components/Jumbotron";
    import LeftBanner from "../../components/LeftBanner";
    import RightBanner from "../../components/RightBanner";

    export default {
        name: "ExternalHome",
        components: {
            Jumbotron,
            LeftBanner,
            RightBanner
        }
    }
</script>

<style scoped>
    .jumbo-container {
        height: 375px;
        width: 100%;
    }

    @media screen and (max-width: 768px) {
        .jumbo-container {
            height: 325px;
        }
        .jumbo {
            padding-top: 2.5em;
        }
    }

    @media screen and (max-width: 900px) {
        .jumbo-container {
            height: 420px;
        }

    }

</style>