<template>
    <v-container fluid>
        <v-row justify="center">
            <v-col cols="12" md="5" sm="8" xs="12">
                <PasswordResetCode :email="email" v-if="emailSent"></PasswordResetCode>
                <v-card flat fluid tile :outlined="getBreakpoint" v-else>
                    <v-row justify="center" class="pt-3">
                        <v-avatar>
                            <v-img src="@/assets/logo.svg"></v-img>
                        </v-avatar>
                    </v-row>
                    <v-card-title class="justify-center text-h6">Reset your password</v-card-title>
                    <v-card-text>
                        <v-row no-gutters justify="center" wrap>
                            <v-spacer></v-spacer>
                            <v-col cols="12" md="10" sm="12" xs="12" class="pt-5">
                                <v-label>Please enter your email to get the code to reset your password.</v-label>
                            </v-col>
                            <v-spacer></v-spacer>
                        </v-row>
                        <v-form v-model="isValid">
                            <v-row no-gutters class="pt-5" wrap>
                                <v-spacer></v-spacer>
                                <v-col cols="12" md="10" sm="12" xs="12">
                                    <v-text-field dense outlined :prepend-inner-icon="'mdi-email'" v-model="email" label="Email" :rules="emailRules" required placeholder=" " class="txt-field shrink"></v-text-field>
                                </v-col>
                                <v-spacer></v-spacer>
                            </v-row>
                            <v-row no-gutters wrap justify="center" v-if="errorMsg">
                                <v-col cols="12" md="10" sm="12" xs="12">
                                    <v-alert text dense type="error">
                                        {{errorMsg}}
                                    </v-alert>
                                </v-col>
                            </v-row>
                            <v-row justify="center">
                                <v-col cols="12" md="9" sm="12" xs="12" class="pl-2 pr-2 text-center" v-if="loading">
                                    <v-progress-circular :size="30" color="secondary" indeterminate></v-progress-circular>
                                </v-col>

                                <v-col cols="12" md="10" sm="12" xs="12" class="text-center" v-else>
                                    <v-btn @click="initResetPassword" color="primary" block :disabled="!isValid">Continue</v-btn>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import account from "../../api/account";
    import PasswordResetCode from "./PasswordResetCode";
    export default {
        name: "PasswordReset",
        components: {
            PasswordResetCode,
        },
        data(){
            return {
                isValid: false,
                email: '',
                emailRules: [
                    v => !!v || 'E-mail is required',
                    v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
                ],
                errorMsg: null,
                emailSent: false,
                code: '',
                loading: false
            }
        },
        computed: {
            getBreakpoint(){
                return this.$vuetify.breakpoint.name == 'xs' ? false : true;
            }
        },
        methods: {
            initResetPassword(){
                this.loading = true;
                account.initResetPassword(this.email).then(res => {
                    console.log(res.data);
                    this.loading = false;
                    this.email = res.data.email;
                    this.emailSent = true;
                }).catch(err => {
                    console.log(err.response.data.error);
                    this.errorMsg = err.response.data.error;
                    this.loading = false;
                })
            },
        }
    }
</script>

<style scoped>

</style>