<template>
    <v-container fluid>
        <v-row justify="center">
            <v-col cols="12" md="12" sm="12" xs="12">
                <v-card class="justify-center" flat tile :outlined="getBreakpoint">
                    <v-row justify="center" class="pt-3">
                        <v-avatar>
                            <v-img src="@/assets/logo.svg"></v-img>
                        </v-avatar>
                    </v-row>
                    <v-card-title class="justify-center text-h6 ">Enter Security Code</v-card-title>
                    <v-card-text>
                        <v-row no-gutters justify="center" wrap>
                            <v-spacer></v-spacer>
                            <v-col cols="12" md="10" sm="12" xs="12" class="pt-5">
                                <v-label>Enter the code in the email sent to {{email}}</v-label>
                            </v-col>
                            <v-spacer></v-spacer>
                        </v-row>
                        <v-row no-gutters class="pt-5" wrap>
                            <v-spacer></v-spacer>
                            <v-col cols="12" md="10" sm="12" xs="12">
                                <v-text-field dense outlined  v-model="code" :rules="codeRules" label="Security Code" required placeholder=" " class="txt-field shrink"></v-text-field>
                            </v-col>
                            <v-spacer></v-spacer>
                        </v-row>
                        <v-row no-gutters justify="center" v-if="!emailSent">
                            <v-spacer></v-spacer>
                            <v-col cols="12" md="10" sm="12" xs="12" class="pl-2">
                                <a class="blue-grey--text text-decoration-underline" @click="onResendEmail">Resend Email</a>
                            </v-col>
                            <v-spacer></v-spacer>
                        </v-row>
                        <v-row no-gutters justify="center" v-else>
                            <v-spacer></v-spacer>
                            <v-col cols="12" md="10" sm="12" xs="12" class="pl-2">
                                <span class="blue-grey--text">Email Sent.</span>
                            </v-col>
                            <v-spacer></v-spacer>
                        </v-row>
                        <v-row no-gutters wrap justify="center" v-if="errorMsg">
                            <v-col cols="12" md="10" sm="12" xs="12" class="pl-2 pr-2">
                                <v-alert text dense type="error">
                                    {{errorMsg}}
                                </v-alert>
                            </v-col>
                        </v-row>
                        <v-row justify="center">
                            <v-col cols="12" md="10" sm="12" xs="12" class="pl-2 pr-2 text-center" v-if="loading">
                                <v-progress-circular :size="30" color="secondary" indeterminate></v-progress-circular>
                            </v-col>
                            <v-col cols="12" md="10" sm="12" xs="12" v-else>
                                <v-btn @click="resetPassword" color="primary" block :disabled="code.length < 6">Continue</v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>

            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import account from "../../api/account";
    export default {
        name: "PasswordResetCode",
        components: {},
        props: {
            email: String
        },
        data(){
            return {
                code: '',
                codeRules: [v => !!v || 'Required.'],
                emailSent: false,
                errorMsg: null,
                loading: false,
            }
        },
        computed: {
            getBreakpoint(){
                return this.$vuetify.breakpoint.name == 'xs' ? false : true;
            }
        },
        methods: {
            resetPassword(){
                //navigate to new password
                this.$router.push({
                    name: 'NewPassword',
                    params: {sub: this.email, code: this.code}
                });
            },
            onResendEmail(){
                this.loading = true;
                account.initResetPassword(this.email).then(res => {
                    console.log(res.data.email);
                    this.emailSent = true;
                    this.loading = false;
                }).catch(err => {
                    console.log(err.response.data.error);
                    this.errorMsg = err.response.data.error;
                    this.loading = false;
                })
            }
        }
    }
</script>

<style scoped>

</style>