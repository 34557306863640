<template>
    <v-footer padless absolute app>
        <v-card flat tile class="flex" color="secondary">
            <v-card-text class="white--text text-center">
                <v-row justify="center" no-gutters>
                    <v-col cols="12" md="12" sm="12" xs="12">
                        <img contain src="@/assets/images/logo.svg" height="45" alt="Meals by the day logo" />
                    </v-col>
                </v-row>
                <v-row class="hidden-sm-and-up" style="padding-left: 1em">
                    <v-col cols="4" md="3" sm="3" xs="3">
                        <v-btn icon :href="'https://play.google.com/store/apps/details?id=com.mealsbytheday.mbdpro.prod'" class="">
                            <v-img height="70" max-width="150" contain src="@/assets/images/google-play-badge.png"/>
                        </v-btn>
                    </v-col>
                    <v-col cols="5" md="3" sm="3" xs="3">
                        <v-btn icon :href="'https://apps.apple.com/us/app/meals-by-the-day-pro/id1540663292'" class="">
                            <v-img height="70" contain max-width="150" src="@/assets/images/apple-store.svg"/>
                        </v-btn>
                    </v-col>
                    <v-col cols="3" md="3" sm="3" xs="3">
                        <v-btn icon :href="'https://www.amazon.com/dp/B08QJMCY8L/?ref-suffix=ss_copy'" class="">
                            <v-img contain src="@/assets/images/alexa.png"/>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row class="hidden-xs-only" justify="center">
                    <v-col cols="12" md="2" sm="3" xs="3" align="right">
                        <v-btn icon :href="'https://play.google.com/store/apps/details?id=com.mealsbytheday.mbdpro.prod'" class="">
                            <v-img height="70" max-width="150" contain src="@/assets/images/google-play-badge.png"/>
                        </v-btn>
                    </v-col>
                    <v-col cols="12" md="2" sm="3" xs="3" align="center" style="padding-left: 2em">
                        <v-btn icon :href="'https://apps.apple.com/us/app/meals-by-the-day-pro/id1540663292'" class="">
                            <v-img height="70" contain max-width="150" src="@/assets/images/apple-store.svg"/>
                        </v-btn>
                    </v-col>
                    <v-col cols="12" md="2" sm="3" xs="3" align="left">
                        <v-btn icon :href="'https://www.amazon.com/dp/B08QJMCY8L/?ref-suffix=ss_copy'" class="">
                            <v-img contain src="@/assets/images/alexa.png"/>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row justify="center" no-gutters>
                    <v-col cols="12" md="12" sm="12" xs="12">
                        <a href="privacy" class="white--text text-decoration-underline text-body-1">Privacy Policy</a>
                    </v-col>
                </v-row>
                <v-row justify="center" no-gutters>
                    <v-col cols="12" md="12" sm="12" xs="12" class="pt-0">
                                <span class="text-md-caption text-center">
                                    Google Play and the Google Play logo are trademarks of Google LLC.
                                </span>
                    </v-col>
                </v-row>
                <v-row justify="center" no-gutters>
                    <v-col cols="12" md="12" sm="12" xs="12" class="pt-0">
                                <span class="text-center text-sm-caption">
                                Apple, the Apple logo, iPhone, and iPad are trademarks of Apple Inc., registered in the U.S.
                                and other countries and regions. App Store is a service mark of Apple Inc.
                                </span>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-footer>

</template>

<script>
    export default {
        name: "TheFooter"
    }
</script>

<style scoped>
    .jumbo-padding-google {
        padding-right: 4em;
    }
    .jumbo-padding-apple {
        padding-left: 4em;
    }
    .jumbo-padding-apple-only {
        padding-left: 0em;
    }

    @media screen and (max-width: 768px) {
        .jumbo-padding-google {
            padding-right: 4em;
        }
        .jumbo-padding-apple {
            padding-left: 4em;
        }
        .jumbo-padding-apple-xs {
            padding-left: 5em;
        }
        .jumbo-padding-google-xs {
            padding-right: 5em;
        }
    }
</style>